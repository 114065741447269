import axios from 'axios';
import Swal from 'sweetalert2';
import { actionTypes } from '../store/ducks/auth.duck';
import moment from 'moment';
import { useEffect, useState } from 'react';

export const regexEmail = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;

export const createFormikValidation = (formMap, values, translate) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = translate("Fields.validation.required", { fieldName: value });;
		} else if (key === 'email' && !regexEmail.test(values[key])) {
			errors[key] = translate("Fields.validation.email");
		}
	}

	return errors;
};

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
};

export const formatHourInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1:$2");

	input.target.value = value;

	return input;
};

export const formatCNPJInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCPFInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatDataInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatTelefoneInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCepInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatBRLInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const formatUSDInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatUSDEnglish(input.target.value);

	return input;
};

export const formatBRL = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const formatIntegerInput = input => {
	if (input.target.value == '') {
		input.target.value = '';
		return input;
	}

	input.target.value = Number(input.target.value.toString().replace(/[^0-9]/g, ''));
	return input;
};

export const formatNumberWithSeparators = value => {
	return value ? value.toLocaleString('pt') : 0;
};

export const formatUSD = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);


	return value;
};

export const formatUSDEnglish = value => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);


	return value;
};

export const formatWithCommas = (value) => {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance.get(`https://viacep.com.br/ws/${cep}/json/`, {}).then(res => {
			setFieldValue(nomeEndereco, res.data.logradouro);
			setFieldValue(nomeBairro, res.data.bairro);
			setFieldValue(nomeCidade, res.data.localidade);
			setFieldValue(nomeUf, res.data.uf);
		}).catch(err => {
		});
	}

	return formatCepInput(input);
};

export const limparMoedaUSD = (valor) => {
	if (typeof valor !== 'string') return valor;

	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.replace("$", "");
	valor = valor.replace("US", "");
	valor = valor.trim();

	return valor;
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.replace("$", "");
	valor = valor.replace("US", "");
	valor = valor.trim();

	return valor;
};

export const listaUfs = [
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MG',
	'MS',
	'MT',
	'PA',
	'PB',
	'PE',
	'PI',
	'PR',
	'RJ',
	'RN',
	'RO',
	'RR',
	'RS',
	'SC',
	'SE',
	'SP',
	'TO'
];

export const displayValueCurrency = (value) => {
	return value ? `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : "Não disponível";
};

export const formatTwoDecimalHouses = (value) => {
	let formatted = displayValueFixed(value, 2);

	return formatted == "Não disponível" ? 0 : formatted.replace('.', ',');
}

export const displayValueFixed = (value, casas) => {
	return value ? value.toFixed(casas) : "Não disponível";
};

export const displayValuePercent = (value) => {
	return value ? `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} %` : "Não disponível";
};

export const difficultyList = [
	{ key: 0, label: 'Setup' },
	{ key: 1, label: 'Beginner' },
	{ key: 2, label: 'Intermediate' },
	{ key: 3, label: 'Hard' },
];

export const getDifficultyLabel = (key) => {
	let label = '';

	difficultyList.forEach((obj, i) => {
		if (obj.key === key) {
			label = obj.label;
		};
	});

	return label;
};

export const checkIfUserHasPermission = (permissions, controller, method) => {
	let hasPermission = false;

	if (permissions) {
		permissions.map(element => {
			if (element.grp_controller === controller && element.grp_method === method) {
				hasPermission = true;
			}
		});
	}


	return hasPermission;
};

export const getStatusTypes = () => {
	return [
		{ value: 'Ativo', translateKey: "Types.getStatusTypes.active" }, { value: 'Inativo', translateKey: "Types.getStatusTypes.inactive" }
	];
};

export const getProductType = (value) => {
	return [
		"Juicebarads", "Denakop"
	];
}

export const getAdNetworkStatusTypes = () => {
	return [
		{ value: 'Em exibição', translateKey: "Types.getAdNetworkStatusTypes.inExhibition" }, { value: 'Pausado', translateKey: "Types.getAdNetworkStatusTypes.paused" }
	];
};

export const getAdNetworkIsSubdomain = () => {
	return [
		'False', 'True'
	];
};

export const getUserStatusTypes = () => {
	return [
		{ value: 'Ativo', translateKey: "Types.getUserStatusTypes.active" },
		{ value: 'Inativo', translateKey: "Types.getUserStatusTypes.inactive" },
		{ value: 'Banido', translateKey: "Types.getUserStatusTypes.banned" }
	];
};

export const getCurrencyTypes = () => {
	return [
		'USD', 'BRL'
	];
};

export const getAccountIsEnabledTypes = () => {
	return [
		{ value: 1, translateKey: "Types.getAccountIsEnabledTypes.1" },
		{ value: 0, translateKey: "Types.getAccountIsEnabledTypes.0" },
	];
};

export const getBooleanTypes = () => {
	return [
		{ value: 1, translateKey: "Types.getBooleanTypes.1" },
		{ value: 0, translateKey: "Types.getBooleanTypes.0" },
	];
};

export const getAccountTierTypes = () => {
	return [
		{ value: 1, translateKey: "Types.getAccountTierTypes.1" },
		{ value: 2, translateKey: "Types.getAccountTierTypes.2" },
		{ value: 3, translateKey: "Types.getAccountTierTypes.3" },
		{ value: 4, translateKey: "Types.getAccountTierTypes.4" },
		{ value: 5, translateKey: "Types.getAccountTierTypes.5" },
	];
};

export const getUserLocaleTypes = () => {
	return [
		{ value: "en_US", translateKey: "Types.getUserLocaleTypes.en_US" },
		{ value: "it_IT", translateKey: "Types.getUserLocaleTypes.it_IT" },
		{ value: "pt_BR", translateKey: "Types.getUserLocaleTypes.pt_BR" },
		{ value: "es_ES", translateKey: "Types.getUserLocaleTypes.es_ES" },
	];
};

export const getWebsiteStatusTypes = () => {
	return [
		{ value: 0, translateKey: "Types.getWebsiteStatusTypes.0" },
		{ value: 1, translateKey: "Types.getWebsiteStatusTypes.1" },
		{ value: 2, translateKey: "Types.getWebsiteStatusTypes.2" },
		{ value: 3, translateKey: "Types.getWebsiteStatusTypes.3" },
	];
};

export const isEmpty = (value) => value === null || value === undefined || value === "";

export const getAutoAdNameType = () => {
	return [
		{ value: "first", format: "First Banner",  translateKey: "Types.getAutoAdNameType.first" },
		{ value: "intext", format: "In-text Banner", translateKey: "Types.getAutoAdNameType.intext" },
		{ value: "side", format: "Side Banner", translateKey: "Types.getAutoAdNameType.side" },
		{ value: "scroll", format: "Scroll Banner",  translateKey: "Types.getAutoAdNameType.scroll" },
		{ value: "under", format: "Under Banner", translateKey: "Types.getAutoAdNameType.under" },
		{ value: "inimage", format: "In-Image Banner", translateKey: "Types.getAutoAdNameType.inimage" },
		{ value: "postitial", format: "Postitial Banner", translateKey: "Types.getAutoAdNameType.postitial" },
		{ value: "slider", format: "Video Slider Banner", translateKey: "Types.getAutoAdNameType.slider" },
		{ value: "top", format: "Top Banner", translateKey: "Types.getAutoAdNameType.top" },
		{ value: "horizontal", format: "Horizontal", translateKey: "Types.getAutoAdNameType.horizontal" },
		{ value: "square", format: "Square", translateKey: "Types.getAutoAdNameType.square" },
		{ value: "vertical", format: "Vertical", translateKey: "Types.getAutoAdNameType.vertical" },
		{ value: "native", format: "Native", translateKey: "Types.getAutoAdNameType.native" },
		{ value: "rewarded", format: "Rewarded Banner", translateKey: "Types.getAutoAdNameType.rewarded" },
		{ value: "video", format: "Video", translateKey: "Types.getAutoAdNameType.video" },


	];
};

export const getDeviceTypes = () => {
	return [
		"desktop", "tablet", "mobile"
	]
};

export const getTechnologyTypes = () => {
	return [
		"HTML", "AMP"
	]
};

export const formatPercentageInputWithFractionalValues = (input, newOptions = { canBeEmpty: false, canExceedLimit: false }) => {
	if (input.target.value === '') {
		if (newOptions.canBeEmpty) {
			return input;
		}

		input.target.value = '0';
	}

	input.target.value = input.target.value.replace(',', '.').replace(/[^0-9\^.\/]/g, '');

	const splitedValue = input.target.value.split('.');

	if (splitedValue.length > 1) {
		let secondSplittedValue = splitedValue[1];

		if (secondSplittedValue.length > 2) {
			secondSplittedValue = splitedValue[1].slice(0, 2);
		};

		input.target.value = `${splitedValue[0]}.${secondSplittedValue}`;
	}

	if (input.target.value === '.' || input.target.value.startsWith('..')) {
		input.target.value = '0';
	}

	if (!input.target.value.startsWith('0.') && input.target.value.length > 1 && input.target.value[0] === '0') input.target.value = input.target.value.slice(1);

	if (input.target.value.startsWith('.')) input.target.value = '0' + input.target.value;

	if (!newOptions.canExceedLimit && Number(input.target.value) > 100) input.target.value = '100';

	return input;
};

export function setupAxios(axios, store) {
	axios.interceptors.request.use(config => {
		const { auth: { authToken } } = store.getState();

		if (authToken) {
			config.headers.Authorization = `Bearer ${authToken}`;
		}

		return config;
	},
		err => Promise.reject(err)
	);

	axios.interceptors.response.use(function (response) {
		return response;
	}, function (error) {

		if (error?.response?.status == 401) {
			store.dispatch({ type: actionTypes.Logout, payload: null });
		}

		// if(error.response.status == 403) {
		//   window.location.href = '/error/error-v3';
		// }

		return Promise.reject(error);
	});
}

export function setStorage(key, value, expires) {
	if (expires === undefined || expires === null) {
		expires = 24 * 60 * 60; // default: seconds for 1 day
	}

	const now = Date.now(); //millisecs since epoch time, lets deal only with integer
	const schedule = now + expires * 1000;
	try {
		localStorage.setItem(key, value);
		localStorage.setItem(key + "_expiresIn", schedule);
	} catch (e) {
		// console.log(
		// 	"setStorage: Error setting key [" +
		// 	key +
		// 	"] in localStorage: " +
		// 	JSON.stringify(e)
		// );
		return false;
	}
	return true;
}

export const copyTextToClipboard = (value, t) => {
	navigator.clipboard.writeText(value).then(
		() => {
			Swal.fire(t("Global.successSwal"), t("Global.successCopying"), 'success');
		},
		() => {
			Swal.fire(t("Global.ops"), t("Global.errorCopying"), 'warning');
		},
	);
};

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

// todo task alterar as timezones
export const getAccountTimeZoneTypes = () => {
	return [
		'Africa/Abidjan',
		'Africa/Accra',
		'Africa/Addis_Ababa',
		'Africa/Algiers',
		'Africa/Asmara',
		'Africa/Bamako',
		'Africa/Bangui',
		'Africa/Banjul',
		'Africa/Bissau',
		'Africa/Blantyre',
		'Africa/Brazzaville',
		'Africa/Bujumbura',
		'Africa/Cairo',
		'Africa/Casablanca',
		'Africa/Ceuta',
		'Africa/Conakry',
		'Africa/Dakar',
		'Africa/Dar_es_Salaam',
		'Africa/Djibouti',
		'Africa/Douala',
		'Africa/El_Aaiun',
		'Africa/Freetown',
		'Africa/Gaborone',
		'Africa/Harare',
		'Africa/Johannesburg',
		'Africa/Juba',
		'Africa/Kampala',
		'Africa/Khartoum',
		'Africa/Kigali',
		'Africa/Kinshasa',
		'Africa/Lagos',
		'Africa/Libreville',
		'Africa/Lome',
		'Africa/Luanda',
		'Africa/Lubumbashi',
		'Africa/Lusaka',
		'Africa/Malabo',
		'Africa/Maputo',
		'Africa/Maseru',
		'Africa/Mbabane',
		'Africa/Mogadishu',
		'Africa/Monrovia',
		'Africa/Nairobi',
		'Africa/Ndjamena',
		'Africa/Niamey',
		'Africa/Nouakchott',
		'Africa/Ouagadougou',
		'Africa/Porto-Novo',
		'Africa/Sao_Tome',
		'Africa/Tripoli',
		'Africa/Tunis',
		'Africa/Windhoek',
		'America/Adak',
		'America/Anchorage',
		'America/Anguilla',
		'America/Antigua',
		'America/Araguaina',
		'America/Argentina/Buenos_Aires',
		'America/Argentina/Catamarca',
		'America/Argentina/Cordoba',
		'America/Argentina/Jujuy',
		'America/Argentina/La_Rioja',
		'America/Argentina/Mendoza',
		'America/Argentina/Rio_Gallegos',
		'America/Argentina/Salta',
		'America/Argentina/San_Juan',
		'America/Argentina/San_Luis',
		'America/Argentina/Tucuman',
		'America/Argentina/Ushuaia',
		'America/Aruba',
		'America/Asuncion',
		'America/Atikokan',
		'America/Bahia',
		'America/Bahia_Banderas',
		'America/Barbados',
		'America/Belem',
		'America/Belize',
		'America/Blanc-Sablon',
		'America/Boa_Vista',
		'America/Bogota',
		'America/Boise',
		'America/Cambridge_Bay',
		'America/Campo_Grande',
		'America/Cancun',
		'America/Caracas',
		'America/Cayenne',
		'America/Cayman',
		'America/Chicago',
		'America/Chihuahua',
		'America/Ciudad_Juarez',
		'America/Costa_Rica',
		'America/Creston',
		'America/Cuiaba',
		'America/Curacao',
		'America/Danmarkshavn',
		'America/Dawson',
		'America/Dawson_Creek',
		'America/Denver',
		'America/Detroit',
		'America/Dominica',
		'America/Edmonton',
		'America/Eirunepe',
		'America/El_Salvador',
		'America/Fort_Nelson',
		'America/Fortaleza',
		'America/Glace_Bay',
		'America/Goose_Bay',
		'America/Grand_Turk',
		'America/Grenada',
		'America/Guadeloupe',
		'America/Guatemala',
		'America/Guayaquil',
		'America/Guyana',
		'America/Halifax',
		'America/Havana',
		'America/Hermosillo',
		'America/Indiana/Indianapolis',
		'America/Indiana/Knox',
		'America/Indiana/Marengo',
		'America/Indiana/Petersburg',
		'America/Indiana/Tell_City',
		'America/Indiana/Vevay',
		'America/Indiana/Vincennes',
		'America/Indiana/Winamac',
		'America/Inuvik',
		'America/Iqaluit',
		'America/Jamaica',
		'America/Juneau',
		'America/Kentucky/Louisville',
		'America/Kentucky/Monticello',
		'America/Kralendijk',
		'America/La_Paz',
		'America/Lima',
		'America/Los_Angeles',
		'America/Lower_Princes',
		'America/Maceio',
		'America/Managua',
		'America/Manaus',
		'America/Marigot',
		'America/Martinique',
		'America/Matamoros',
		'America/Mazatlan',
		'America/Menominee',
		'America/Merida',
		'America/Metlakatla',
		'America/Mexico_City',
		'America/Miquelon',
		'America/Moncton',
		'America/Monterrey',
		'America/Montevideo',
		'America/Montserrat',
		'America/Nassau',
		'America/New_York',
		'America/Nome',
		'America/Noronha',
		'America/North_Dakota/Beulah',
		'America/North_Dakota/Center',
		'America/North_Dakota/New_Salem',
		'America/Nuuk',
		'America/Ojinaga',
		'America/Panama',
		'America/Paramaribo',
		'America/Phoenix',
		'America/Port-au-Prince',
		'America/Port_of_Spain',
		'America/Porto_Velho',
		'America/Puerto_Rico',
		'America/Punta_Arenas',
		'America/Rankin_Inlet',
		'America/Recife',
		'America/Regina',
		'America/Resolute',
		'America/Rio_Branco',
		'America/Santarem',
		'America/Santiago',
		'America/Santo_Domingo',
		'America/Sao_Paulo',
		'America/Scoresbysund',
		'America/Sitka',
		'America/St_Barthelemy',
		'America/St_Johns',
		'America/St_Kitts',
		'America/St_Lucia',
		'America/St_Thomas',
		'America/St_Vincent',
		'America/Swift_Current',
		'America/Tegucigalpa',
		'America/Thule',
		'America/Tijuana',
		'America/Toronto',
		'America/Tortola',
		'America/Vancouver',
		'America/Whitehorse',
		'America/Winnipeg',
		'America/Yakutat',
		'America/Yellowknife',
		'Antarctica/Casey',
		'Antarctica/Davis',
		'Antarctica/DumontDUrville',
		'Antarctica/Macquarie',
		'Antarctica/Mawson',
		'Antarctica/McMurdo',
		'Antarctica/Palmer',
		'Antarctica/Rothera',
		'Antarctica/Syowa',
		'Antarctica/Troll',
		'Antarctica/Vostok',
		'Arctic/Longyearbyen',
		'Asia/Aden',
		'Asia/Almaty',
		'Asia/Amman',
		'Asia/Anadyr',
		'Asia/Aqtau',
		'Asia/Aqtobe',
		'Asia/Ashgabat',
		'Asia/Atyrau',
		'Asia/Baghdad',
		'Asia/Bahrain',
		'Asia/Baku',
		'Asia/Bangkok',
		'Asia/Barnaul',
		'Asia/Beirut',
		'Asia/Bishkek',
		'Asia/Brunei',
		'Asia/Chita',
		'Asia/Choibalsan',
		'Asia/Colombo',
		'Asia/Damascus',
		'Asia/Dhaka',
		'Asia/Dili',
		'Asia/Dubai',
		'Asia/Dushanbe',
		'Asia/Famagusta',
		'Asia/Gaza',
		'Asia/Hebron',
		'Asia/Ho_Chi_Minh',
		'Asia/Hong_Kong',
		'Asia/Hovd',
		'Asia/Irkutsk',
		'Asia/Jakarta',
		'Asia/Jayapura',
		'Asia/Jerusalem',
		'Asia/Kabul',
		'Asia/Kamchatka',
		'Asia/Karachi',
		'Asia/Kathmandu',
		'Asia/Khandyga',
		'Asia/Kolkata',
		'Asia/Krasnoyarsk',
		'Asia/Kuala_Lumpur',
		'Asia/Kuching',
		'Asia/Kuwait',
		'Asia/Macau',
		'Asia/Magadan',
		'Asia/Makassar',
		'Asia/Manila',
		'Asia/Muscat',
		'Asia/Nicosia',
		'Asia/Novokuznetsk',
		'Asia/Novosibirsk',
		'Asia/Omsk',
		'Asia/Oral',
		'Asia/Phnom_Penh',
		'Asia/Pontianak',
		'Asia/Pyongyang',
		'Asia/Qatar',
		'Asia/Qostanay',
		'Asia/Qyzylorda',
		'Asia/Riyadh',
		'Asia/Sakhalin',
		'Asia/Samarkand',
		'Asia/Seoul',
		'Asia/Shanghai',
		'Asia/Singapore',
		'Asia/Srednekolymsk',
		'Asia/Taipei',
		'Asia/Tashkent',
		'Asia/Tbilisi',
		'Asia/Tehran',
		'Asia/Thimphu',
		'Asia/Tokyo',
		'Asia/Tomsk',
		'Asia/Ulaanbaatar',
		'Asia/Urumqi',
		'Asia/Ust-Nera',
		'Asia/Vientiane',
		'Asia/Vladivostok',
		'Asia/Yakutsk',
		'Asia/Yangon',
		'Asia/Yekaterinburg',
		'Asia/Yerevan',
		'Atlantic/Azores',
		'Atlantic/Bermuda',
		'Atlantic/Canary',
		'Atlantic/Cape_Verde',
		'Atlantic/Faroe',
		'Atlantic/Madeira',
		'Atlantic/Reykjavik',
		'Atlantic/South_Georgia',
		'Atlantic/St_Helena',
		'Atlantic/Stanley',
		'Australia/Adelaide',
		'Australia/Brisbane',
		'Australia/Broken_Hill',
		'Australia/Darwin',
		'Australia/Eucla',
		'Australia/Hobart',
		'Australia/Lindeman',
		'Australia/Lord_Howe',
		'Australia/Melbourne',
		'Australia/Perth',
		'Australia/Sydney',
		'Europe/Amsterdam',
		'Europe/Andorra',
		'Europe/Astrakhan',
		'Europe/Athens',
		'Europe/Belgrade',
		'Europe/Berlin',
		'Europe/Bratislava',
		'Europe/Brussels',
		'Europe/Bucharest',
		'Europe/Budapest',
		'Europe/Busingen',
		'Europe/Chisinau',
		'Europe/Copenhagen',
		'Europe/Dublin',
		'Europe/Gibraltar',
		'Europe/Guernsey',
		'Europe/Helsinki',
		'Europe/Isle_of_Man',
		'Europe/Istanbul',
		'Europe/Jersey',
		'Europe/Kaliningrad',
		'Europe/Kirov',
		'Europe/Kyiv',
		'Europe/Lisbon',
		'Europe/Ljubljana',
		'Europe/London',
		'Europe/Luxembourg',
		'Europe/Madrid',
		'Europe/Malta',
		'Europe/Mariehamn',
		'Europe/Minsk',
		'Europe/Monaco',
		'Europe/Moscow',
		'Europe/Oslo',
		'Europe/Paris',
		'Europe/Podgorica',
		'Europe/Prague',
		'Europe/Riga',
		'Europe/Rome',
		'Europe/Samara',
		'Europe/San_Marino',
		'Europe/Sarajevo',
		'Europe/Saratov',
		'Europe/Simferopol',
		'Europe/Skopje',
		'Europe/Sofia',
		'Europe/Stockholm',
		'Europe/Tallinn',
		'Europe/Tirane',
		'Europe/Ulyanovsk',
		'Europe/Vaduz',
		'Europe/Vatican',
		'Europe/Vienna',
		'Europe/Vilnius',
		'Europe/Volgograd',
		'Europe/Warsaw',
		'Europe/Zagreb',
		'Europe/Zurich',
		'Indian/Antananarivo',
		'Indian/Chagos',
		'Indian/Christmas',
		'Indian/Cocos',
		'Indian/Comoro',
		'Indian/Kerguelen',
		'Indian/Mahe',
		'Indian/Maldives',
		'Indian/Mauritius',
		'Indian/Mayotte',
		'Indian/Reunion',
		'Pacific/Apia',
		'Pacific/Auckland',
		'Pacific/Bougainville',
		'Pacific/Chatham',
		'Pacific/Chuuk',
		'Pacific/Easter',
		'Pacific/Efate',
		'Pacific/Fakaofo',
		'Pacific/Fiji',
		'Pacific/Funafuti',
		'Pacific/Galapagos',
		'Pacific/Gambier',
		'Pacific/Guadalcanal',
		'Pacific/Guam',
		'Pacific/Honolulu',
		'Pacific/Kanton',
		'Pacific/Kiritimati',
		'Pacific/Kosrae',
		'Pacific/Kwajalein',
		'Pacific/Majuro',
		'Pacific/Marquesas',
		'Pacific/Midway',
		'Pacific/Nauru',
		'Pacific/Niue',
		'Pacific/Norfolk',
		'Pacific/Noumea',
		'Pacific/Pago_Pago',
		'Pacific/Palau',
		'Pacific/Pitcairn',
		'Pacific/Pohnpei',
		'Pacific/Port_Moresby',
		'Pacific/Rarotonga',
		'Pacific/Saipan',
		'Pacific/Tahiti',
		'Pacific/Tarawa',
		'Pacific/Tongatapu',
		'Pacific/Wake',
		'Pacific/Wallis',
		'UTC',
	];
};

export const getDatesFromFilterInterval = (filterInterval) => {
	let startDate = null;
	let endDate = null;

	switch (filterInterval) {
		case "yesterday":
			startDate = moment(new Date()).subtract(1, "day").format('YYYY-MM-DD');
			endDate = moment(new Date()).subtract(1, "day").format('YYYY-MM-DD');
			break;
		case "lastMonth":
			startDate = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
			endDate = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;
		case "lastSevenDays":
			startDate = moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD');
			endDate = moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD');
			break;
		case "lastThirdDays":
			startDate = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
			endDate = moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD');
			break;
		default:
			startDate = moment(new Date()).subtract(4, 'days').format('YYYY-MM-DD');
			endDate = moment(new Date()).format('YYYY-MM-DD');
			break;
	}

	return { startDate, endDate };
};

export const getFilterIntervalFromDates = (formattedStartDate, formattedEndDate) => {

	if (formattedStartDate === moment(new Date()).subtract(1, "day").format('YYYY-MM-DD') &&
		formattedEndDate === moment(new Date()).subtract(1, "day").format('YYYY-MM-DD')
	) {
		return "yesterday";
	}

	if (formattedStartDate === moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD') &&
		formattedEndDate === moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
	) {
		return "lastMonth";
	}

	if (formattedStartDate === moment(new Date()).subtract(1, 'weeks').format('YYYY-MM-DD') &&
		formattedEndDate === moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD')
	) {
		return "lastSevenDays";
	}

	if (formattedStartDate === moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD') &&
		formattedEndDate === moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD')
	) {
		return "lastThirdDays";
	}


	return "custom";
};

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};

export const formatDate = (date, withHours) => {
	if (moment(date).isValid()) {
		if (withHours) return moment(date).format('DD/MM/YYYY HH:mm:ss');

		return moment(date).format('DD/MM/YYYY');
	}

	return date;
}

export const sortWebsitesByAccountName = (websites) => {
	if (!websites) return [];

	return websites.sort((a, b) => {
		if (!a?.account) {
			return -1;
		}

		if (!b?.account) {
			return -1;
		}

		return a.account.acc_name.localeCompare(b.account.acc_name);
	});
}